import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from './slices/user';
import companyReducer from './slices/company';
import storeReducer from './slices/store';
import product_tagReducer from './slices/product_tag';
import productReducer from './slices/product';
import product_categoryReducer from './slices/product_category';
import employeeReducer from './slices/employee';
import bankReducer from './slices/bank';
import expenseReducer from './slices/expense';
import expense_categoryReducer from './slices/expense_category';
import expense_tagReducer from './slices/expense_tag';
import incomeReducer from './slices/income';
import income_categoryReducer from './slices/income_category';
import purchase_orderReducer from './slices/purchase_order';
import salesReducer from './slices/sales';
import reportReducer from './slices/report';

// ----------------------------------------------------------------------
const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  // whitelist: ["user"],
  whitelist: [],
};

const rootReducer = combineReducers({
  user : userReducer,
  company : companyReducer,
  store : storeReducer,
  product_tag : product_tagReducer,
  product : productReducer,
  product_category : product_categoryReducer,
  employee : employeeReducer,
  bank : bankReducer,
  expense : expenseReducer,
  expense_category : expense_categoryReducer,
  expense_tag : expense_tagReducer,
  income : incomeReducer,
  income_category : income_categoryReducer,
  purchase_order : purchase_orderReducer,
  sales : salesReducer,
  report : reportReducer,
});
const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

const persistor = persistStore(store);

const { dispatch } = store;
const useSelector = useAppSelector;
const useDispatch = () => useAppDispatch();

export { store, persistor, dispatch, useSelector, useDispatch };
// export { store, persistor, dispatch , useDispatch  };
