import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
// import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
// import { ReactComponent as LogoSingle } from '../assets/logo_single.svg';
// import LogoSingle from '../assets/logo_single.png';
// import FingerPrint from '../assets/finger_print.png';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  height: PropTypes.number,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx, height = 50 }) {
  // const theme = useTheme();


  const logo = ( 
    <Box >
      <img
          src='/logo/Nomu.png'
          style={{ height , width: 'auto' }}
          alt="website logo"
          sx={{...sx}}
        />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }
  return <RouterLink to="/">{logo}</RouterLink>;
}
