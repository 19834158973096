// @mui
// import { enUS, frFR, zhCN, viVN, arSD } from '@mui/material/locale';
// routes
// import { PATH_DASHBOARD } from './routes/paths';

// API !2#4%qazwsx!
// ----------------------------------------------------------------------

export const HOST_URL = "https://manage.nomubeauty.my.id";
export const HOST_API = `${HOST_URL}/api`;

// PROD
export const reCaptchaV3SiteKey = "6LdzcwYqAAAAANk71V6KKsDp6UOR7Fapev6kuvrL";

// LOCAL
// export const reCaptchaV3SiteKey = "6Ld7sb8nAAAAANKg7dfC1_knrOptxkV2qlNi0kUp";
// export const reCaptchaV3SiteKey = "6Lep784pAAAAAJ0cRatdApVL5WKPufxZpfp4RT-j";


// export const mapBoxAccessToken = "pk.eyJ1IjoiZHJhbWRhbmkiLCJhIjoiY2tuNnR5bzdoMDlyMzJxbXp0enlxbWV3diJ9.u63fK50My5pK_-IK00XtwA";
// export const mapBoxAccessToken = "";

// ROOT PATH AFTER LOGIN SUCCESSFUL
// export const PATH_AFTER_LOGIN = PATH_DASHBOARD.root; // as '/dashboard/app'
// ----------------------------------------------------------------------

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeContrast: 'default',
  themeLayout: 'vertical', // 'vertical' 'horizontal'
  themeColorPresets: 'default', // 'default'
  themeStretch: false,
};


